import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import Layout from '../../components/Layout'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Section from '../../components/Layout/Section'
import Explore from '../../components/Product/Explore'
import ExploreByBrand from '../../components/Product/ExploreByBrand'
import { CirclePicker } from 'react-color'
import Icon from '../../images/icons.svg'
import Arrives from '../../images/arrives.png'
import Warranty from '../../images/warranty.png'
import USA from '../../images/usa.png'
import DocumentBlock from '../../components/Document'
import SmallVideoBlock from '../../components/SmallVideos'
import Title from '../../components/Layout/Title'
import FeaturedProductItem from '../../components/FeaturedProduct/FeaturedProductItem'
import {
  buildProductBreadcrumb,
  buildProductName,
  isJsonString,
} from '../../helpers/theme'
import { formatPrice } from '../../helpers/price'
import Button from '../../components/UI/Button/Button'
import { addProductToCart } from '../../helpers/cart'
import { get, graphql, post } from '../../helpers/fetch'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useStaticQuery, Link, navigate } from 'gatsby'
import { getCartItems, setCartItems, createGuestCart } from '../../store/cart'
import BlackHeart from '../../images/black_heart.svg'
import {
  addItemToWishlist,
  getWishlistItems,
  removeItemFromWishlist,
} from '../../store/wishlist'
import ProductModel from '../../models/product'
import { Helmet } from 'react-helmet'

import AppIcons from '../../data/icons'
import { getQuoteItems, setQuoteItems } from '../../store/quote'
import moment from 'moment'
import { getCustomerCartToken } from '../../store/auth'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow next"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="small-chevron-arrow prev"
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

// markup
const Product = ({
  pageContext: { product: rawProduct, productAttributes },
}) => {
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [allProducts, setAllProducts] = useState([])
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const { id: cartId, token: cartToken } = useSelector(state => state.cart)
  const quoteId = useSelector(state => state.quote.id)
  const currentUser = useSelector(state => state.auth.user)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [qty, setQty] = useState(1)
  const [item, setItem] = useState(new ProductModel(rawProduct, priceRuleItems))
  const [bundleOptions, setBundleOptions] = useState([])
  const [thumbSlider, setThumbSlider] = useState(null)

  const [showTableHeight, setShowTableHeight] = useState(false)
  const [showOverallHeight, setShowOverallHeight] = useState(false)
  const [showSeatWidth, setShowSeatWidth] = useState(false)
  const [showSeatHeight, setShowSeatHeight] = useState(false)
  const [valid, setValid] = useState(false)
  const [adding, setAdding] = useState(false)

  useEffect(() => {
    fetch('/products.json')
      .then(res => res.json())
      .then(setAllProducts)
      .catch(err => {
        console.error(err)
        setAllProducts([])
      })
  }, [])

  useEffect(() => {
    if (allProducts && allProducts.length > 0) {
      if (allProducts.some(p => p.id === rawProduct.id)) {
        setValid(true)
      } else {
        navigate('/404')
      }
    }
  }, [allProducts, rawProduct])

  const [selectedOptions, setSelectedOptions] = useState(null)
  const [selectedColour, setSelectedColour] = useState(null)

  const product = useMemo(
    () => new ProductModel(rawProduct, priceRuleItems),
    [rawProduct, priceRuleItems]
  )

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    asNavFor: nav2,
    ref: slider1 => setNav1(slider1),
  }

  const thumbSettings = {
    dots: false,
    infinite: false,
    arrow: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    asNavFor: nav1,
    ref: slider2 => setNav2(slider2),
    slidesToShow: 7,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1544,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  const handlePlus = () => {
    setQty(qty + 1)
  }

  const handleMinus = () => {
    if (qty > 0) {
      setQty(qty - 1)
    }
  }

  function handleValueChange(e) {
    e.preventDefault()
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const x = Number(e.target.value)
      setQty(x)
    }
  }

  const buildQuoteItemsQuery = useCallback(
    bundleOptions => {
      return `
    { 
      quantity: ${qty},
      product_id: ${item.id},
      bundle_option: [
        ${bundleOptions
          .map(option => {
            return `
            {
              id: ${option.option_id},
              value: ${option.selectedOption.id},
              ${
                !!option.selectedOption.attributes
                  ? `
              attributes: [
                ${Object.values(option.selectedOption.attributes).map(
                  _ => `{ id: ${_.id}, value: ${_.value} }`
                )}
              ]
              `
                  : ''
              }
            }
          `
          })
          .join(',')}
      ]
    }
  `
    },
    [qty, item]
  )

  const addToCart = useCallback(() => {
    if (item.isBundle) {
      if (!validateBundle(bundleOptions)) {
        toast.error(`Your options are not correct. Please try again.`, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        })
        return
      }

      const quoteItemsQuery = buildQuoteItemsQuery(bundleOptions)

      graphql(
        `${process.env.GATSBY_CMS_URL}/graphql`,
        `
        mutation {
          AddItemsToQuote(
            quote_id: "${cartToken}"
            quote_items: [${quoteItemsQuery}]
          )
        }
      `
      )
        .then(res => {
          if (!res?.errors) {
            dispatch(getCartItems())
            dispatch(getQuoteItems())
            toast(`Added product ${item?.name} to cart`, {
              position: 'top-right',
              autoClose: 1000,
              closeOnClick: true,
            })
          } else {
            toast.error(
              res.errors.map(e => e.debugMessage || e.message).join('. '),
              {
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: true,
              }
            )
          }
        })
        .catch(e => {
          console.error(e)
          toast.error(
            `An error occurred while adding this product to cart. Please try again.`,
            {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
            }
          )
        })
      return
    }
    setAdding(true);
    addProductToCart(cartToken, item.sku, qty, token)
      .then(cart => {
        dispatch(setCartItems(cart.items))
        dispatch(getQuoteItems())
        setAdding(false);
        toast(`Added product ${item?.name} to cart`, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
        })
      })
      .catch(e => {
        console.error(e)
        setAdding(false);
        dispatch(createGuestCart())
        toast.error(
          `An error occurred while adding this product to cart. Please try again.`,
          {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
          }
        )
      })
  }, [
    qty,
    item,
    addProductToCart,
    cartToken,
    token,
    validateBundle,
    bundleOptions,
    cartId,
    getCartItems,
    buildQuoteItemsQuery,
    getQuoteItems,
  ])

  const addToQuote = useCallback(() => {
    if (!token) {
      navigate('/login?backUrl=' + encodeURIComponent(window.location.pathname))
    } else {
      if (quoteId) {
        if (item.isBundle) {
          if (!validateBundle(bundleOptions)) {
            toast.error(`Your options are not correct. Please try again.`, {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
            })
            return
          }

          const quoteItemsQuery = buildQuoteItemsQuery(bundleOptions)

          graphql(
            `${process.env.GATSBY_CMS_URL}/graphql`,
            `
            mutation {
              AddItemsToQuote(
                quote_id: "${cartToken}"
                quote_items: [${quoteItemsQuery}]
              )
            }
          `
          )
            .then(res => {
              if (!res?.errors) {
                dispatch(getQuoteItems())
                dispatch(getCartItems())
                toast(`Added product ${item?.name} to quote`, {
                  position: 'top-right',
                  autoClose: 3000,
                  closeOnClick: true,
                })
              } else {
                toast.error(
                  res.errors.map(e => e.debugMessage || e.message).join('. '),
                  {
                    position: 'top-right',
                    autoClose: 3000,
                    closeOnClick: true,
                  }
                )
              }
            })
            .catch(e => {
              console.error(e)
              toast.error(
                `An error occurred while adding this product to quote. Please try again.`,
                {
                  position: 'top-right',
                  autoClose: 3000,
                  closeOnClick: true,
                }
              )
            })
          return
        }
        // add to quote by quote token // same like cart token
        graphql(
          `${process.env.GATSBY_CMS_URL}/graphql`,
          `
          mutation {
            AddItemsToQuote(
              quote_id: ${quoteId}
              quote_items: [{ quantity: ${qty}, product_id: ${item.id} }]
            )
          }
        `
        )
          .then(res => {
            if (!res?.errors) {
              dispatch(getQuoteItems())
              dispatch(getCartItems())
              toast(`Added product ${item?.name} to your quote`, {
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: true,
              })
            } else {
              toast.error(
                res.errors.map(e => e.debugMessage || e.message).join('. '),
                {
                  position: 'top-right',
                  autoClose: 3000,
                  closeOnClick: true,
                }
              )
            }
          })
          .catch(e => {
            console.error(e)
            toast.error(
              `An error occurred while adding this product to quote. Please try again.`,
              {
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: true,
              }
            )
          })
      }
    }
  }, [
    qty,
    item,
    getQuoteItems,
    quoteId,
    token,
    navigate,
    dispatch,
    buildQuoteItemsQuery,
    bundleOptions,
    validateBundle,
    getCartItems,
  ])

  const wishlistItems = useSelector(state => state.wishlist.items)

  const wishlistId = useMemo(() => {
    const foundWishlist = wishlistItems.find(
      wItem => wItem.product.sku === item.sku
    )
    return foundWishlist ? foundWishlist.id : null
  }, [wishlistItems, item.sku])

  const backURL =
    typeof window !== 'undefined'
      ? encodeURIComponent(
          `${window.location.origin}/wishlist?product_sku=${item.sku}`
        )
      : ''
  const addToWishlist = useCallback(() => {
    if (!token) {
      navigate('/login?backURL=' + backURL)
      return
    }
    console.log(bundleOptions)
    dispatch(addItemToWishlist({
      sku: item.sku,
      options: bundleOptions.map(p => p.selectedOption?.uid).filter(_ => _)
    }))
  }, [item, token, currentUser, bundleOptions])

  const removeFromWishlist = useCallback(() => {
    if (!token) {
      navigate('/login')
      return
    }
    dispatch(removeItemFromWishlist(wishlistId))
  }, [token, wishlistId])

  useEffect(() => {
    if (rawProduct.variants && rawProduct.variants.length > 0) {
      const { product: variantObj, attributes } = rawProduct.variants[0]
      setItem(new ProductModel(variantObj, priceRuleItems))
      const preselectedOptions = {}
      for (const attr of attributes) {
        preselectedOptions[attr.code] = attr.value_index
        if (attr.code == 'colour') {
          setSelectedColour(attr.label)
        }
      }
      setSelectedOptions(preselectedOptions)

      rawProduct.variants.map(function (item, i) {
        if (item.product.table_height) {
          setShowTableHeight(true)
        }

        if (item.product.overall_height) {
          setShowOverallHeight(true)
        }

        if (item.product.seat_width) {
          setShowSeatWidth(true)
        }

        if (item.product.seat_height) {
          setShowSeatHeight(true)
        }
      })
    }
  }, [rawProduct, priceRuleItems])

  const bundleTotal = useMemo(() => {
    if (bundleOptions && bundleOptions.length) {
      const total = bundleOptions.reduce((acc, opt) => {
        let p = [0,0]
        if (
          opt.selectedOption?.product.isSimple &&
          opt.selectedOption?.product.salePrice
        ) {
          p = [opt.selectedOption?.product.salePrice, opt.selectedOption?.product.price]
        } else {
          if (opt.selectedOption?.variant?.product) {
            const v = new ProductModel(
              {
                ...opt.selectedOption?.variant.product,
              },
              priceRuleItems
            )
            p = [v.salePrice || 0, v.price || 0]
          } else {
            p = [opt.selectedOption?.product.salePrice || 0, opt.selectedOption?.product.price || 0]
          }
        }
        acc[0] += p[0] * opt.selectedOption?.quantity * (1 - product.percentOff / 100)
        acc[1] += p[1] * opt.selectedOption?.quantity
        return acc
      }, [0,0])
      return total
    }
    return [0, 0]
  }, [bundleOptions, priceRuleItems, product])

  const pricePanel = useMemo(() => {
    if (!item) return

    const currency = item.currency || 'AUD'
    let basePrice = item.salePrice
    let fullPrice = item.price

    if (item.isBundle) {
      basePrice = bundleTotal[0]
      fullPrice = bundleTotal[1]
    }

    const price = basePrice * qty

    return (
      <>
        <div className="price">
          <h4>{formatPrice(price + (price * 10) / 100, currency)}</h4>
          {basePrice < fullPrice && (
            <p className="old-price">
              {formatPrice(
                (fullPrice + (fullPrice * 10) / 100) * qty,
                currency
              )}
            </p>
          )}
        </div>
        {<p className="text-sm">Excl. GST: {formatPrice(price, currency)}</p>}
      </>
    )
  }, [item, bundleTotal, qty])

  // useEffect(() => {
  //   if (!nav1 || !item) return
  //   if (product.variants) {
  //     const index = product.variants.findIndex(v => v.product.id === item.id)
  //     nav1.slickGoTo(index)
  //   }
  // }, [item, product, nav1])

  useEffect(() => {
    if (!selectedOptions) return
    const selectedVariant = product.variants.find(({ attributes }) => {
      return Object.keys(selectedOptions).every(key => {
        const value = selectedOptions[key]
        return attributes.some(
          _attr => _attr.code === key && _attr.value_index === value
        )
      })
    })

    if (selectedVariant) {
      setItem(new ProductModel(selectedVariant.product, priceRuleItems))
    } else {
      if (Object.keys(selectedOptions).length > 0) {
        const firstKey = Object.keys(selectedOptions)[0]
        const firstValue = Object.values(selectedOptions)[0]
        const _v = product.variants.find(({ attributes }) => {
          return attributes.some(
            _attr => _attr.code === firstKey && _attr.value_index === firstValue
          )
        })
        if (_v) {
          setSelectedOptions(_prev => {
            const newOptions = { ..._prev }
            for (const key in _prev) {
              newOptions[key] = parseInt(_v.product[key], 10)
            }
            return newOptions
          })
        }
      }
    }
  }, [product, selectedOptions, priceRuleItems])

  const isOptionsValid = useCallback(
    ops => {
      return product.variants.some(({ attributes }) => {
        return Object.keys(ops).every(key => {
          const value = ops[key]
          return attributes.some(
            _attr => _attr.code === key && _attr.value_index === value
          )
        })
      })
    },
    [product]
  )

  const productBrand = useMemo(() => {
    let configBrand
    if (productAttributes) {
      configBrand = productAttributes.find(
        a => a.attribute_code === 'product_brand'
      )
    }

    if (!configBrand) return null

    const optBrand = configBrand.attribute_options.find(
      op => op.value == product.product_brand
    )
    if (!optBrand) return null

    return optBrand.label
  }, [productAttributes, product.product_brand])

  const productRange = useMemo(() => {
    let configRange
    if (productAttributes) {
      configRange = productAttributes.find(a => a.attribute_code === 'range')
    }

    if (!configRange) return null

    const optRange = configRange.attribute_options.find(
      op => op.value == product.range && op.label != 'None'
    )
    if (!optRange) return null

    return optRange.label
  }, [productAttributes, product.range])

  const getAge = useCallback(
    ageId => {
      if (typeof productAttributes === 'undefined') return null
      const configAges = productAttributes.find(
        a => a.attribute_code === 'ages'
      )
      if (!configAges) return null

      const optAges = configAges.attribute_options.find(op => op.value == ageId)
      if (!optAges) return null

      return optAges.label
    },
    [productAttributes]
  )

  const BASE_PRODUCT_IMAGE_URL = `${process.env.SS_CMS_BASEURL}/assets/`
  const [productImages, setProductImages] = useState([])
  const [detailImage, setDetailImage] = useState(null)
  const handleFetchProductImages = async () => {
    if (product.isBundle) {
      if (product.media_gallery && product.media_gallery.length > 0) {
        setProductImages(product.media_gallery)
        if (product.media_gallery.length > 1) {
          setDetailImage(product.media_gallery[1])
        } else if (product.media_gallery.length > 0) {
          setDetailImage(product.media_gallery[0])
        }
      }
    } else {
      // let sku = product.sku.replace('-', '')
      // sku = sku.replace(/ +/g, '-')
      let imagesUrl = `${process.env.SS_CMS_BASEURL}/grocorpapi/getproductimages?sku=${product.order_code}`
      const response = await fetch(imagesUrl)
      const data = await response.json()
      if (response.status === 200) {
        const itemsObjects = Object.keys(data).map(key => data[key])
        const _productImages = []
        for (let i = 0; i < itemsObjects.length; i++) {
          let _image = { url: `${BASE_PRODUCT_IMAGE_URL}${itemsObjects[i]}` }
          _productImages.push(_image)
        }
        setProductImages(_productImages)
        if (_productImages.length > 1) {
          setDetailImage(_productImages[1])
        } else if (_productImages.length > 0) {
          setDetailImage(_productImages[0])
        }
      }
    }
  }

  useEffect(() => {
    handleFetchProductImages()
  }, [])

  const validateBundle = useCallback(bOptions => {
    return bOptions.every(option => {
      if (option.selectedOption?.product.isSimple) {
        return true
      }
      if (option.selectedOption?.product.isConfigurable) {
        return !!option.selectedOption?.variant?.product
      }
      return false
    })
  }, [])

  const icons = useMemo(() => {
    if (item.icons) {
      const iconNames = item.icons.split(',')
      return iconNames
        .filter(name => AppIcons[name])
        .map(name => AppIcons[name])
    }
    return []
  }, [item])

  const isOutOfStock = useMemo(() => {
    if (!item || item.stock_status === 'OUT_OF_STOCK') return true
    return (
      (item.categories || []).some(_c => _c.url_key.includes('clearance')) &&
      item.only_x_left_in_stock === 0
    )
  }, [item])

  const isChairsAndSeating = useMemo(() => {
    return (product.categories || []).some(_c =>
      _c.url_key.includes('chairs-seating')
    )
  }, [product])

  useEffect(() => {
    if (!item.isBundle) return

    const bundleItems = JSON.parse(JSON.stringify(item.items))
    for (const _item of bundleItems) {
      for (const _option of _item.options) {
        const matchedProduct = allProducts.find(
          _ => _.uid === _option.product.uid
        )
        if (matchedProduct) {
          _option.product = new ProductModel(
            { ...matchedProduct },
            priceRuleItems
          )
        }
        if (_option.availableVariants && _option.availableVariants.length > 0) {
          _option.availableVariants = _option.availableVariants
            .map(v => allProducts.find(_ => _.id == v))
            .filter(_ => _)
        }
      }
      const defaultOption = _item.options.find(_ => _.is_default)
      _item.selectedOption = defaultOption || _item.options[0]
    }

    setBundleOptions(bundleItems)
  }, [item, allProducts, priceRuleItems])

  function isSelected(v) {
    const bundleItems = JSON.parse(JSON.stringify(item.items))
    for (const _item of bundleItems) {
      for (const _option of _item.options) {
        if (_option.product.configurable_product_options_selection) {
          for (const _optionSelected of _option.product
            .configurable_product_options_selection.configurable_options) {
            for (const _optionSelectedValue of _optionSelected.values) {
              if (
                _optionSelectedValue.is_available &&
                _optionSelectedValue.label === v.label
              ) {
                return true
              }
            }
          }
        }
      }
    }
    return false
  }

  useEffect(() => {
    if (!item || !thumbSlider || !product.isConfigurable || productImages.length === 0) return
    const slideIndex = productImages.findIndex(pi => pi.url.includes(`${item.order_code}.`))
    if (slideIndex < 0) return
    thumbSlider.slickGoTo(slideIndex)
  }, [product, item, productImages, thumbSlider])

  if (!valid) return <></>

  return (
    <Layout>
      <Helmet>
        <title>{product.meta_title}</title>
        <meta name="title" content={product.meta_title} />
        <meta name="description" content={product.meta_description} />
        <meta name="keywords" content={product.meta_keyword} />
        {/* <link rel="canonical" href={canonicalURL} /> */}
      </Helmet>
      <div className="product-details">
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              {productImages.length > 0 && (
                <Slider {...settings} className="product-image-slider">
                  {productImages.map((image, index) => (
                    <div
                      className="product-image-item"
                      key={`main-${index}-${image.url}`}
                    >
                      <img src={image.url} />
                      <div className="btn-zoom">
                        <svg width="20" height="20">
                          <use xlinkHref={`${Icon}#icon-search`}></use>
                        </svg>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {productImages.length > 0 && (
                <Slider {...thumbSettings} className="product-thumb-slider" ref={s => setThumbSlider(s)}>
                  {productImages.map((image, index) => (
                    <div
                      className="product-thumb-item"
                      key={`thumb-${index}-${image.url}`}
                    >
                      <img src={image.url} />
                    </div>
                  ))}
                </Slider>
              )}
            </Col>
            <Col xs={12} lg={6}>
              <div className="product-info">
                <div className="d-flex align-item-start justify-content-between">
                  {/*{!!productBrand && (*/}
                  {/*  <p className="subtitle subtitle-sm">{productBrand}</p>*/}
                  {/*)}*/}

                  {!!productRange && (
                    <p className="subtitle subtitle-sm">{productRange}</p>
                  )}
                  <button
                    className="btn-like ms-auto"
                    onClick={e => {
                      e.preventDefault()
                      if (wishlistId) {
                        removeFromWishlist()
                      } else {
                        addToWishlist()
                      }
                    }}
                  >
                    {wishlistId ? (
                      <img width={28} height={28} src={BlackHeart} />
                    ) : (
                      <svg viewBox="0 0 512 512" width="28" height="28">
                        <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="info-top">
                  <h1 className="h3">{item.name}</h1>
                  {isOutOfStock && <span className="tags">OUT OF STOCK</span>}
                  {!!item?.order_code && <p className="text-sm text-secondary">
                    CODE: {item.order_code}
                  </p>}
                </div>

                <div className="info-group border-bottom-0">{pricePanel}</div>

                {item.isBundle && bundleOptions.length > 0 && (
                  <div className="info-group">
                    {bundleOptions.map(bundleOption => {
                      return (
                        <div className="mb-2">
                          <div className="d-flex flex-wrap align-item-start justify-content-start">
                            <h5 className="me-3">{bundleOption.title}</h5>
                            {!!bundleOption.selectedOption && (
                              <span className="text-sm text-secondary mb-1">
                                {bundleOption.selectedOption.quantity} x{' '}
                                {bundleOption.selectedOption.label}
                              </span>
                            )}
                          </div>
                          {bundleOption.options.length > 0 && (
                            <Row>
                              <Col xs={12} xl={12}>
                                <Form.Group
                                  className="form-group mb-4"
                                  controlId="Select1"
                                >
                                  <Form.Label className="text-sm">
                                    Item *
                                  </Form.Label>
                                  <Form.Select
                                    onChange={e => {
                                      const uid = e.target.value
                                      setBundleOptions(_bundleOptions => {
                                        const currentBundleOption =
                                          _bundleOptions.find(
                                            _ => _.uid === bundleOption.uid
                                          )
                                        const selectedOption =
                                          currentBundleOption.options.find(
                                            _ => _.uid === uid
                                          )
                                        currentBundleOption.selectedOption =
                                          selectedOption
                                        return [..._bundleOptions]
                                      })
                                    }}
                                    required
                                  >
                                    {bundleOption.options.map(_option => (
                                      <option
                                        value={_option.uid}
                                        selected={_option.is_default}
                                      >
                                        {_option.quantity} x {_option.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              {bundleOption.selectedOption.product
                                .isConfigurable && (
                                <Row>
                                  {bundleOption.selectedOption.product.configurable_options.map(
                                    option => {
                                      return (
                                        <Col xs={12} xl={6}>
                                          <Form.Group
                                            className="form-group mb-4"
                                            controlId="Select1"
                                          >
                                            <Form.Label className="text-sm">
                                              {option.label}
                                            </Form.Label>
                                            <Form.Select
                                              onChange={e => {
                                                const value = e.target.value
                                                if (
                                                  !bundleOption.selectedOption
                                                    .attributes
                                                ) {
                                                  bundleOption.selectedOption.attributes =
                                                    {}
                                                }
                                                bundleOption.selectedOption.attributes[
                                                  option.attribute_code
                                                ] = {
                                                  id: option.attribute_id,
                                                  value,
                                                }
                                                const keys = Object.keys(
                                                  bundleOption.selectedOption
                                                    .attributes
                                                )
                                                const variant =
                                                  bundleOption.selectedOption.product.variants.find(
                                                    v => {
                                                      if (
                                                        keys.length !==
                                                        v.attributes.length
                                                      ) {
                                                        return false
                                                      }

                                                      return keys.every(k => {
                                                        const kv =
                                                          bundleOption
                                                            .selectedOption
                                                            .attributes[k].value
                                                        const va =
                                                          v.attributes.find(
                                                            _ => _.code === k
                                                          )
                                                        return (
                                                          kv == va.value_index
                                                        )
                                                      })
                                                    }
                                                  )
                                                bundleOption.selectedOption.variant =
                                                  bundleOption.selectedOption.availableVariants.some(
                                                    av =>
                                                      av.id ==
                                                      variant?.product?.id
                                                  )
                                                    ? variant
                                                    : null
                                                setBundleOptions(_ => [..._])
                                              }}
                                              required
                                            >
                                              <option value="">
                                                Choose an Option
                                              </option>
                                              {option.values
                                                .filter(v => {
                                                  return bundleOption.selectedOption.availableVariants.some(
                                                    av =>
                                                      av[
                                                        option.attribute_code
                                                      ] == v.value_index
                                                  )
                                                })
                                                .map(v => {
                                                  return isSelected(v) ? (
                                                    <option
                                                      value={v.value_index}
                                                    >
                                                      {v.label}
                                                    </option>
                                                  ) : null
                                                })}
                                            </Form.Select>
                                          </Form.Group>
                                        </Col>
                                      )
                                    }
                                  )}
                                </Row>
                              )}
                            </Row>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}

                {!!selectedOptions &&
                  product.configurable_options.map(
                    (configOption, _configIndex) => (
                      <div
                        className="info-group"
                        key={`${configOption.attribute_code}-v-group`}
                      >
                        {configOption.attribute_code === 'colour' ? (
                          <div className="d-flex align-item-start justify-content-between">
                            <div>
                              <p className="h5">{configOption.label}</p>
                              <p className="text-sm text-secondary">
                                {selectedColour}
                              </p>
                            </div>
                            <div>
                              <CirclePicker
                                colors={configOption.values
                                  .filter(
                                    optionVal =>
                                      _configIndex === 0 ||
                                      isOptionsValid({
                                        ...selectedOptions,
                                        [configOption.attribute_code]:
                                          optionVal.value_index,
                                      })
                                  )
                                  .filter(
                                    optionVal =>
                                      optionVal.value_index !== null &&
                                      !!optionVal.swatch_data?.value
                                  )
                                  .map(
                                    optionVal => optionVal.swatch_data?.value
                                  )}
                                color={
                                  selectedOptions
                                    ? configOption.values.find(
                                        _val =>
                                          _val.value_index ===
                                          selectedOptions[
                                            configOption.attribute_code
                                          ]
                                      ).swatch_data?.value
                                    : null
                                }
                                onChange={color => {
                                  setSelectedOptions(_options => {
                                    const findOption = configOption.values.find(
                                      _val => {
                                        if (
                                          _val.swatch_data &&
                                          _val.swatch_data.value
                                        ) {
                                          return (
                                            _val.swatch_data.value.toLowerCase() ===
                                            color.hex.toLowerCase()
                                          )
                                        }
                                        return false
                                      }
                                    )
                                    setSelectedColour(findOption.label)
                                    if (findOption) {
                                      _options[configOption.attribute_code] =
                                        findOption.value_index
                                    }
                                    return { ..._options }
                                  })
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="size-group">
                              {configOption.values
                                .filter(
                                  optionVal =>
                                    _configIndex === 0 ||
                                    isOptionsValid({
                                      ...selectedOptions,
                                      [configOption.attribute_code]:
                                        optionVal.value_index,
                                    })
                                )
                                .filter(
                                  optionVal => optionVal.value_index !== null
                                )
                                .map((optionVal, index) => (
                                  <div
                                    className="radio-group"
                                    key={`${optionVal.value_index}-${configOption.attribute_code}`}
                                  >
                                    <input
                                      type="radio"
                                      id={`option-${configOption.attribute_code}-${optionVal.value_index}`}
                                      value={optionVal.value_index}
                                      checked={
                                        selectedOptions
                                          ? optionVal.value_index ===
                                            selectedOptions[
                                              configOption.attribute_code
                                            ]
                                          : false
                                      }
                                      onChange={() => {
                                        setSelectedOptions(_options => {
                                          _options[
                                            configOption.attribute_code
                                          ] = optionVal.value_index
                                          return { ..._options }
                                        })
                                      }}
                                    />
                                    <label
                                      htmlFor={`option-${configOption.attribute_code}-${optionVal.value_index}`}
                                    >
                                      {optionVal.label}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </>
                        )}
                      </div>
                    )
                  )}
                <div className="info-group">
                  <p className="h5">QUANTITY</p>
                  <div className="quantity-group mt-3 mb-5">
                    <button onClick={handleMinus}>-</button>
                    <input
                      type="number"
                      min="0"
                      value={qty}
                      onChange={handleValueChange}
                    />
                    <button onClick={handlePlus}>+</button>
                  </div>
                  {!validateBundle(bundleOptions) && (
                    <p className="text-danger">
                      Your options are not available, please reselect.
                    </p>
                  )}
                  <div className="btn-wrap">
                    {isOutOfStock ? (
                      <a
                        onClick={() => {}}
                        className="btn btn-primary disabled"
                      >
                        Out of stock
                      </a>
                    ) : (
                      <button disabled={adding} onClick={addToCart} className="btn btn-primary">
                          {adding? "Adding to Cart" : "Add to Cart" }
                      </button>
                    )}
                    {item.showQuote && (
                      <a
                        onClick={addToQuote}
                        className="btn btn-outline-primary"
                      >
                        Add to Quote
                      </a>
                    )}
                  </div>
                </div>
                <div className="info-group">
                  {icons.length > 0 && (
                    <div className="image-group">
                      {icons.map((_icon, idx) => (
                        <img
                          width={95}
                          src={_icon}
                          key={`icon-${idx}-${_icon}`}
                        />
                      ))}
                    </div>
                  )}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: product.description.html,
                    }}
                  ></span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Section noSpacingBottom noSpacingTop className="bg-gray">
        <DocumentBlock
          item={item}
          htmlContent={product.short_description.html}
          detailImage={detailImage}
        />
      </Section>
      {isChairsAndSeating &&
        (showTableHeight ||
          showSeatHeight ||
          showSeatWidth ||
          showOverallHeight) && (
          <Section>
            {/* based on table block */}
            <Container>
              <Title className="text-center">
                <h5>PRODUCT DIMENSIONS</h5>
              </Title>
              <Table className="table-block" responsive>
                <thead>
                  <tr>
                    <th width="270">Name</th>
                    <th>Code</th>
                    {!!showTableHeight && <th>Table Height</th>}
                    {!!showOverallHeight && <th>Overall Height</th>}
                    {!!showSeatWidth && <th>Seat Width</th>}
                    {!!showSeatHeight && <th>Seat Height</th>}
                  </tr>
                </thead>
                <tbody>
                  {product.variants.map(({ product: _v }) => (
                    <tr key={`table-variant-${_v.sku}`}>
                      <td>{_v.name}</td>
                      <td>{_v.order_code}</td>
                      {!!showTableHeight && <td>{_v.table_height || 'n/a'}</td>}
                      {!!showOverallHeight && (
                        <td>{_v.overall_height || 'n/a'}</td>
                      )}
                      {!!showSeatWidth && <td>{_v.seat_width || 'n/a'}</td>}
                      {!!showSeatHeight && <td>{_v.seat_height || 'n/a'}</td>}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </Section>
        )}
      {<Section className="bg-gray">
        <SmallVideoBlock
            item={item}
        />
      </Section> }
      {!!product && <Explore product={product} allProducts={allProducts} />}
      {!!product && (
        <ExploreByBrand product={product} allProducts={allProducts} relatedProducts={product.related_products || []} options={productAttributes.find(p => p.attribute_code === 'range')?.attribute_options || []} />
      )}
    </Layout>
  )
}

export default Product
