import low_carbon_product from '../images/icons/low_carbon_product.svg'
import made_in_australia from '../images/icons/made_in_australia.svg'
import product_assembled from '../images/icons/product_assembled.svg'
import reclaimed_wood_product from '../images/icons/reclaimed_wood_product.svg'
import recyclable_product_eou from '../images/icons/recyclable_product_eou.svg'
import sustainably_sourced_timber from '../images/icons/sustainably_sourced_timber.svg'
import warranty_5_year from '../images/icons/warranty_5_year.svg'
import warranty_6_months from '../images/icons/warranty_6_months.svg'
import warranty_2_year from '../images/icons/warranty_2_year.svg'
import warranty_10_year from '../images/icons/warranty_10_year.svg'

export default {
  low_carbon_product,
  made_in_australia,
  product_assembled,
  reclaimed_wood_product,
  recyclable_product_eou,
  sustainably_sourced_timber,
  warranty_5_year,
  warranty_6_months,
  warranty_2_year,
  warranty_10_year,
}
