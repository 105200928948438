import React from "react";
import PropTypes from "prop-types";

const Title = ({ className, subTitle, title, children }) => {
  return (
    <>
      <div className={`title ${className ? className : ""}`.trim()}>
        {subTitle && <p className="subtitle subtitle-sm">{subTitle}</p>}
        {title && <h2>{title}</h2>}
        {children}
      </div>
    </>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
};

Title.defautProps = {};

export default Title;
