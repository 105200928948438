import * as React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Icon from '../../images/icons.svg'
import Background from '../../images/document.png'

// markup
const DocumentBlock = ({ item, htmlContent = '', detailImage = null }) => {
  const image = item.detailsImage
    ? item.detailsImage
    : detailImage
    ? detailImage.url
    : null

  const dimensionsItems = [];

  if (item.length && item.length !== "0"){
    dimensionsItems.push(item.length + 'L')
  }

  if (item.width && item.width !== "0"){
    dimensionsItems.push(item.width + 'W')
  }

  if (item.diameter && item.diameter !== null){
    dimensionsItems.push(item.diameter + 'D')
  }

  if (item.height && item.height !== "0"){
    dimensionsItems.push(item.height + 'H')
  }

  const listItems = dimensionsItems.join(' x ');
  return (
    <div className="document">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <p className="h5">DETAILS</p>
            <div
              className="document-content"
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            ></div>
            <br/>
            {((item.material_label && item.material_label !== "false") ||
                item.finish ||
                (item.length && item.length !== "0")  ||
                (item.height && item.height !== "0") ||
                (item.width && item.width !== "0") ||
                item.diameter ) &&(
                <h6>SPECIFICATIONS</h6>
            )}

            {(item.material_label && item.material_label !== "false") && (
                <p>
                  <strong>Material:</strong>&nbsp;{item.material_label}
                </p>
            )}

            {!!item.finish && (
                <p>
                  <strong>Finish:</strong>&nbsp;{item.finish}
                </p>
            )}

            {!!listItems && (
              <p>
                <strong>Dimensions:</strong>&nbsp;
                {listItems}
              </p>
            )}

            {!!item.delivery_information && (
              <>
                <h6>DELIVERY INFORMATION</h6>
                <p>{item.delivery_information}</p>
              </>
            )}

            {(item.details_button_link ||
                item.details_button_text
            ) && (
                <a href={item.details_button_link} className="btn btn-primary w-100">
                  {item.details_button_text}
                </a>
            )}
          </Col>
        </Row>
      </Container>
      {image && (
        <div className="document-image">
          <img src={image} alt="" />
        </div>
      )}
    </div>
  )
}

export default DocumentBlock
