import { Link } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { formatPrice } from '../../../helpers/price'
import { buildProductName, getProductUrlByCategory } from '../../../helpers/theme'
import PlaceholderImage from '../../../images/featured-1.png'
import Product from '../../../models/product'
import ProductThumbnail from '../../Product/Thumbnail'
import FeaturedProductModal from '../FeaturedProductModal'

// markup
const FeaturedProductItem = ({ item }) => {
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [modalShow, setModalShow] = useState(false)

  const product = new Product(item, priceRuleItems)

  const nameArray = buildProductName(product.name)
  const productName = nameArray[0]

  return (
    <>
      <Col xs={6} md={6} lg={3}>
        <div className="featured-item">
          <button className="btn-like">
            <svg viewBox="0 0 512 512" width="28" height="28">
              <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
            </svg>
          </button>
          <div className="featured-item-inner">
            <div className="featured-item-image">
              <ProductThumbnail product={product} />
              <button
                className="featured-item-view"
                onClick={() => setModalShow(true)}
              >
                Quick View
              </button>
            </div>
            <Link to={getProductUrlByCategory(product, 0)} className="h4">
              {productName}
            </Link>
          </div>
          <div className="featured-item-des">
            <strong>{formatPrice(product.salePrice, product.currency)}</strong>
            {/* <p className="text-sm">Excl. GST: $1,300.00</p> */}
          </div>
        </div>
      </Col>
      {/*<FeaturedProductModal*/}
      {/*  show={modalShow}*/}
      {/*  onHide={() => setModalShow(false)}*/}
      {/*  item={product}*/}
      {/*/>*/}
    </>
  )
}

export default FeaturedProductItem
