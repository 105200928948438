import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  buildProductName,
  getProductUrl,
  getProductUrlByCategory,
} from '../../helpers/theme'
import Product from '../../models/product'
import PlaceholderImage from '../../images/featured-1.png'
import Title from '../../components/Layout/Title'
import Section from '../../components/Layout/Section'
import { Col, Container, Row } from 'react-bootstrap'
import FeaturedProductModal from '../FeaturedProduct/FeaturedProductModal'

import { formatPrice } from '../../helpers/price'
import ProductThumbnail from './Thumbnail'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import shuffle from 'lodash/shuffle'

const Explore = ({ product, allProducts }) => {
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [modalShow, setModalShow] = useState(false)
  const [popupItem, setPopupItem] = useState()
  const [items, setItems] = useState([])

  useEffect(() => {
    let relatedProducts = []
    let max = 4
    if (allProducts !== null) {
      allProducts.forEach(function (item, index) {
        if (
          item.url_key !== product.url_key &&
          item.visibility_text !== 'Not Visible Individually'
        ) {
          let categories = item.categories
          const totalCategories = product.categories.length
          if (totalCategories > 0) {
            const matchedCategories = product.categories.reduce((acc, c) => {
              if (categories.some(_c => _c.id === c.id)) {
                acc = acc + 1
              }
              return acc
            }, 0)
            if ((matchedCategories / totalCategories) >= 0.6) {
              const nameArray = buildProductName(item.name)
              item.productName = nameArray[0]
              item.productCat = nameArray[1]
              if (typeof item.thumbnail !== 'undefined') {
                item.imageUrl = item.thumbnail.url
              } else if (typeof item.image !== 'undefined') {
                item.imageUrl = item.image.url
              } else {
                item.imageUrl = PlaceholderImage
              }
              relatedProducts.push(item)
            }
          }
        }
      })
    }

    relatedProducts = shuffle(relatedProducts)
    relatedProducts = relatedProducts.slice(0, max)

    setItems(relatedProducts)
  }, [product, allProducts])

  const ShowPopup = item => e => {
    e.preventDefault()
    setPopupItem(item)
    setModalShow(true)
  }

  return (
    <>
      {items.length > 0 && (
        <Section hasDivider>
          {/* based on featured product */}
          <Container>
            <Title className="text-center" title="Similar Products"></Title>
            <Row className="justify-content-center">
              {items.map((_item, idx) => {
                const item = new Product(_item, priceRuleItems)
                const nameArray = buildProductName(item.name)
                const productName = nameArray[0]
                return (
                  <Col xs={6} md={6} lg={3} key={`related-product-${idx}`}>
                    <Link to={getProductUrlByCategory(_item)}>
                      <div className="featured-item">
                        <button className="btn-like">
                          <svg viewBox="0 0 512 512" width="28" height="28">
                            <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                          </svg>
                        </button>
                        <div className="featured-item-inner">
                          <div className="featured-item-image">
                            <ProductThumbnail product={item} />
                            <button
                              className="featured-item-view"
                              onClick={ShowPopup(item)}
                            >
                              Quick View
                            </button>
                          </div>
                          <a href={getProductUrl(_item)} className="h4">
                            {productName}
                          </a>
                        </div>
                        <div className="featured-item-des">
                          {item.isBundle || item.isConfigurable ? (
                            <Row>
                              <Col xs={12} md={6}>
                                <strong className="price-label">
                                  {item.isBundle && <span>From:&nbsp;</span>}
                                  {formatPrice(
                                    item.minPrice + (item.minPrice * 10) / 100,
                                    item.currency
                                  )}
                                  {item.minFullPrice > item.minPrice && (
                                    <>
                                      <br />
                                      <s className="old-price">
                                        {formatPrice(
                                          item.minFullPrice +
                                            (item.minFullPrice * 10) / 100,
                                          item.currency
                                        )}
                                      </s>
                                    </>
                                  )}
                                </strong>

                                <p className="text-sm">
                                  Excl. GST:{' '}
                                  {formatPrice(item.minPrice, item.currency)}
                                </p>
                              </Col>
                              {item.maxPrice > item.minPrice && (
                                <Col xs={12} md={6}>
                                  <strong className="price-label">
                                    {item.isBundle && <span>To:&nbsp;</span>}
                                    {formatPrice(
                                      item.maxPrice +
                                        (item.maxPrice * 10) / 100,
                                      item.currency
                                    )}
                                    {item.maxFullPrice > item.maxPrice && (
                                      <>
                                        <br />
                                        <s className="old-price">
                                          {formatPrice(
                                            item.maxFullPrice +
                                              (item.maxFullPrice * 10) / 100,
                                            item.currency
                                          )}
                                        </s>
                                      </>
                                    )}
                                  </strong>

                                  <p className="text-sm">
                                    Excl. GST:{' '}
                                    {formatPrice(item.maxPrice, item.currency)}
                                  </p>
                                </Col>
                              )}
                            </Row>
                          ) : (
                            <>
                              <strong>
                                {formatPrice(
                                  item.salePrice + (item.salePrice * 10) / 100,
                                  item.currency
                                )}
                              </strong>
                              {item.price > item.salePrice && (
                                <strong>
                                  &nbsp;
                                  <s>
                                    {formatPrice(
                                      item.price + (item.price * 10) / 100,
                                      item.currency
                                    )}
                                  </s>
                                </strong>
                              )}

                              <p className="text-sm">
                                Excl. GST:{' '}
                                {formatPrice(item.salePrice, item.currency)}
                              </p>
                            </>
                          )}
                          {/* <strong>{formatPrice(item.salePrice, item.currency)}</strong> */}
                          {/*<p className="text-sm">Excl. GST: $1,300.00</p>*/}
                        </div>
                      </div>
                    </Link>
                  </Col>
                )
              })}
            </Row>
            {!!popupItem && (
              <FeaturedProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                item={popupItem}
              />
            )}
          </Container>
        </Section>
      )}
    </>
  )
}

export default Explore
