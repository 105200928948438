import React, { useState } from "react";
import { Container, Row, Col, Modal, Ratio } from "react-bootstrap";
import Title from "../Layout/Title";
import Icon from "../../images/icons.svg";
import PlaceholderImage from "../../images/fullwidth.png";

// function VideoModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Ratio aspectRatio="16x9">
//           <iframe
//             width="100%"
//             height="100%"
//             src="https://www.youtube.com/embed/LILLG_73mL0"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowfullscreen
//           ></iframe>
//         </Ratio>
//       </Modal.Body>
//     </Modal>
//   );
// }

// markup
const SmallVideoBlock = ({ item }) => {
  const [modalShow, setModalShow] = useState(false);
  const [popupVideoURL, setPopupVideoURL] = useState(null)

  const handleClick = videoURL => {
    setPopupVideoURL(videoURL)
    setModalShow(true)
  }

  //console.log(item.video_image_1)

  return (
    <>
      <Container>
        <h5 className="text-center mb-4"></h5>
        <Row>
          {item.video_embed_code_1 && (
              <Col xs={6} lg={6}>
                <div className="video-tile mb-lg-0">
                  <div className="video-tile-inner">
                    {item.video_image_1 && (
                        <img src={`${process.env.GATSBY_CMS_URL}/media/catalog/product${item.video_image_1}`} alt="video image 1" />
                    )}
                    <button className="btn-play" onClick={() => handleClick(item.video_embed_code_1)}>
                      <svg focusable="false" width="18" height="16">
                        <use xlinkHref={`${Icon}#icon-play`}></use>
                      </svg>
                    </button>
                  </div>
                  {item.video_title_1 && (
                      <p>{item.video_title_1}</p>
                  )}
                </div>
              </Col>
          )}

          {item.video_embed_code_2 && (
              <Col xs={6} lg={6}>
                <div className="video-tile mb-lg-0">
                  <div className="video-tile-inner">
                    {item.video_image_2 && (
                      <img src={`${process.env.GATSBY_CMS_URL}/media/catalog/product${item.video_image_2}`} alt="video image 2" />
                    )}
                    <button className="btn-play" onClick={() => handleClick(item.video_embed_code_2)}>
                      <svg focusable="false" width="18" height="16">
                        <use xlinkHref={`${Icon}#icon-play`}></use>
                      </svg>
                    </button>
                  </div>
                  {item.video_title_2 && (
                      <p>{item.video_title_2}</p>
                  )}
                </div>
              </Col>
          )}

        </Row>
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Ratio aspectRatio="16x9">
              <iframe
                  width="100%"
                  height="100%"
                  src={popupVideoURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
              ></iframe>
            </Ratio>
          </Modal.Body>
        </Modal>
      </Container>
      {/*<VideoModal show={modalShow} onHide={() => setModalShow(false)} />*/}
    </>
  );
};

export default SmallVideoBlock;
